import "./s.css";
import OtpInput from "react-otp-input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';

export default function Otp() {
    const [code, setCode] = useState("");
    const navigate = useNavigate();
    const handleChange = (code) => setCode(code);
  
    return (
      <>
    
      <div className="s">
        <h1>OTP</h1>
        <OtpInput
          value={code}
          onChange={handleChange}
          numInputs={6}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={{
            border: "1px solid transparent",
            borderRadius: "8px",
            width: "54px",
            height: "54px",
            fontSize: "12px",
            color: "#000",
            fontWeight: "400",
            caretColor: "blue"
          }}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none"
          }}
        />
         <br></br><br></br>
         <Button label="Submit"   className="p-button-success"  onClick={() => navigate("/Order")}   />
  
      </div>
      </>
    );
  }
  
 