import "./s.css";
 

import React , { useState }  from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
// import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
// import { Skeleton } from 'primereact/skeleton';
// import './DropdownDemo.css';
import './TabViewDemo.css';
export default function Otp() {
    const onqtychange = e => {
        const yourVariable = e.target.value
        setSelectedqty(yourVariable);
        console.log("QTY>"+ yourVariable)
        console.log("-QTY>"+ e.target.value)
    }
    const [checked, setChecked] = useState(false); 
    const qty = [{name: 'X', key: 'A'}, {name: '48', key: 'B'}, {name: '92', key: 'C'}, {name: '140', key: 'D'},{name: '168', key: 'E'}];
    // const [city, setCity] = useState(null);
    const [selectedqty, setSelectedqty] = useState();

    const header = (
        <img alt="Card" src="/images/dno/dno1.jpg" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
    );
    const footer = (
        <span>
            
            <Button label="In Stock" type="button" className="p-button-sm p-button-secondary p-button-text"   /> {'  '} 
            Pcs  <Dropdown value={selectedqty} options={qty} onChange={onqtychange} optionLabel="name" placeholder="SELECT QUANTITY" /> 
              
        </span>
      
          
        
    );





    return (
      <>
    
      <div>
        <h3>Welcome XYZ Company </h3>
         
        <div className="card">
                
                <TabView>
                    
                    <TabPanel header="ITEMS" >
                        {/* <p>All Item List with Filters ,, </p>
                        <p> Go To Top Button</p> 
                        <p>Item Card: pic, stock status, pre order, Up dno Info, main name , description, Rate, QTY to order, Dlt Button</p> 
                        <p>Selected item: Filter Toggle</p>  */}
                       
                      

<Card title="DENIM LEATHER JACKET XL " subTitle="Style#D1     Double Pocket  -Cap - InsideFur  -Rugged Chain" style={{ width: '25em' }}   header={header}>
   {footer} 
    
</Card>





                    </TabPanel>
                    <TabPanel header="SHIPPING" > 
                    {/* Marka, Tpt, stn , add outside item, send items outside, notes : etc.   */}
                    <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">MARKA</span>
                            <InputText placeholder="NAME ON PARCEL" />
                        </div>

                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">SHIPPING</span>
                            <InputText placeholder="TRANSPORT COMPANY NAME" />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">SHIPPER NO.</span>
                            <InputText placeholder="PHONE NUMBER" />
                        </div> 
                        <br></br> 
                        <div className="field-checkbox">
                    <Checkbox inputId="binary" checked={checked} onChange={e => setChecked(e.checked)} />
                    <label htmlFor="binary">ADD GOODS FROM OUTSIDE TO SAME PARCEL</label>
                </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">ANY INSTRUCTIONS</span>
                            <InputText placeholder="THINGS TO REMEMBER .. " />
                        </div>

                        

                         </TabPanel>
                    <TabPanel header="PAY"> 
                    
                    <p><h3>ITEM SUMMARY: 2,640 PCS of  100 DESIGNS</h3></p>
                    <p><h3>TOTA; : 70 LOWER, 20 NIKKER, 10 JACKETS</h3></p>
                    
                    <p><h3>DELIVERY : UTC : 9899889999</h3></p>
                        <p><h3>AMOUNT: 14,22,469/-</h3></p>
                        <Button label="PAY AND CHECKOUT"   className="p-button-info"     />  <br></br>   <br></br> 
                        <Button label="ARRANGE A CALLBACK"   className="p-button-success"     />
                    </TabPanel>

                  
                </TabView>
            </div>
      </div>
      </>
    );
  }
  
 