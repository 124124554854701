 
import React, { useState      } from 'react';
 
 
import './s.css';
import { InputMask } from 'primereact/inputmask';
// import { InputNumber } from 'primereact/inputnumber';
  
import { Button } from 'primereact/button';
// import reportWebVitals from './reportWebVitals';
import { useNavigate } from "react-router-dom";

import { Dialog } from 'primereact/dialog';
import Carousel from 'nuka-carousel'
 
export default function Home() {

 
 const navigate = useNavigate();
 
const [displayBasic1, setDisplayBasic1] = useState(false);
 const [displayBasic2, setDisplayBasic2] = useState(false);
 const [aboutuspop, setaboutuspop] = useState(false);
 const [loginpop, setloginpop] = useState(false);
 const [contactpop, setcontactpop] = useState(false);
 const [phone_no, setphone_no] = useState('');
 
 const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
}

// const go_to_otp = (name) => {
//     dialogFuncMap[`${name}`](false);
     
//     console.log(phone_no)

     
// }
 

const dialogFuncMap = {
    'displayBasic1': setDisplayBasic1,
    'displayBasic2': setDisplayBasic2,
    'aboutuspop': setaboutuspop,
  
    'loginpop' : setloginpop,
    'contactpop' : setcontactpop,
}
// const [position, setPosition] = useState('center');
const onClick = (name  ) => {

   setphone_no('')
    dialogFuncMap[`${name}`](true);

}

const renderFooter_OTP = (name) => {
    return (
        
        <div className="left"  style={{ height: '60vw'  }}>
               
         <div className="phleftn">
         <InputMask   mask="9999999999"    inputMode="numeric"   style={{ width: '80vw'  }} maxlengt={10} value={phone_no} onChange={(e) => setphone_no(e.value)} inputProps={{ inputMode: 'numeric' }}></InputMask>
         </div>
          <div className="phleftp"> <Button   className="p-button-success left" style={{ width: '80vw'  }} label="GET OTP" onClick={() => navigate(`/otp`)} icon="pi pi-check" autoFocus />
            </div> 
        </div>
    );
}
// onClick={() => go_to_otp(name)} 

const renderFooter = (name) => {
    return (
        <div>
            {/* <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" /> */}
            <Button label="     " icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
        </div>
    );
}

return (

<>
 <div className="grid grid-nogutter surface-0 text-800">
    <div className="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
        <section>
        {/* <Carousel   wraparound={true} autoplay={true} autoplayReverse={true} autoplayInterval ={1200}   dragging = {false}  withoutControls={true} >
    <img alt = "" src="images/home/D10.webp" />
    <img alt = "" src="images/home/D11.webp" />
    <img alt = "" src="images/home/D12.webp" />
    <img alt = "" src="images/home/C5.webp" />
  </Carousel> */}
        <img alt="Card" src="/jpng.png" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} /><br></br><br></br> 
            {/* <span className="block text-6xl font-bold mb-1">KailashJ</span><br></br><br></br> */}
 

        
            {/* <Button label="SHOP"   className="p-button-lg p-button-raised p-button-text p-button-plain"  onClick={() => onClick('loginpop' )} /><br></br><br></br> */}
      
        
            
            {/* <Button label="contact us" type="button" className="p-button-sm p-button-secondary p-button-text"  onClick={() => onClick('contactpop' )} />  {'  '}  */}
          
            {/* <Button label="about Us" type="button" className="p-button-sm p-button-secondary p-button-text" onClick={() => onClick('aboutuspop' )} /> {'  '}  */}
   
       
     
        
            {/* <Button label="policy" type="button" className="p-button-sm p-button-secondary p-button-text"  onClick={() => onClick('displayBasic2' )} />  */}
 
            {/* <Button label="t & c" type="button" className="p-button-sm p-button-secondary p-button-text" onClick={() => onClick('displayBasic1' )} /> {'  '}<br></br><br></br> */}
           
           
            {/* <div>Copyright © 2022 Kailash and J for K & J. All rights reserved.</div> */}
            {/* <div>Copyright © 2022 Kailash Hosiery. All rights reserved.</div> */}
            <div>site under construction..</div>
        
        </section>
    </div>
  
</div>


<Dialog header="contact us" visible={contactpop}   style={{ width: '90vw' , height: '90vw' }} footer={renderFooter('contactpop')} onHide={() => onHide('contactpop')}>
                  contact@kailashj.com 
                   
                   </Dialog>



 <Dialog header="about us" visible={aboutuspop} style={{ width: '90vw' , height: '90vw' }} footer={renderFooter('aboutuspop')} onHide={() => onHide('aboutuspop')}>
 Kailash Hosiery is a own circle of manufacturers and businesses, making it feasible for customers round the sector to explicit themselves via style and design,
  and to pick a greater sustainable lifestyle.
  We create affordable fashion for people and society with a focal point on sustainable and worthwhile growth.
                   
                   </Dialog>

<Dialog header="terms & conditions" visible={displayBasic1} style={{ width: '90vw' , height: '90vw' }} footer={renderFooter('displayBasic1')} onHide={() => onHide('displayBasic1')}>

<p>

<br></br><br></br>           
These Terms of Sale of Address, sets out the terms and conditions that will apply when you place an order through Address. Please note that these Terms are subject to change and review from time to time.

<br></br><br></br>
Contact <br></br><br></br>
Email:  help@kailashj.com

 
<br></br><br></br>
Each order submitted constitutes an offer to purchase products. Orders are subject to Address’ acceptance and may be refused at Address’ discretion depending on:
<br></br><br></br>
The availability of the product.
The proximity of the customer location.
Incomplete or false information provided by the user.
 

<br></br><br></br>
All shoppers have to register and login for placing orders on the site.
<br></br><br></br>
Address does not endorse any product, company or brand through this website or outside the website.
<br></br><br></br>
The mail voucher sent to the user’s email id / whatsapp / physical copy via transport    and the order bill after placing an order available for print in the website will be considered as the provisional bill for the product that the customer has placed an order for.
<br></br><br></br>
The same bill will be available to the customer along with the product at the time of delivery.
<br></br><br></br>
Address retains the right to add or delete product information in display depending on the availability of the product and up gradation to product or brand type.
<br></br><br></br>
Order cancellations and request for replacement and refund is user initiated and the user will be responsible for the same.
<br></br><br></br>
Replacement of the product depends on the availability of the product and the customer will have to wait for the duration by when the product will be available at Address’ end, next.
<br></br><br></br>
A customer can track his/her order anytime using the order tracking information available in the website or over mail whichever is available or suggested by us.
<br></br><br></br>
Personal information provided by the user to Address will be used only for the purpose of the business transaction for which it is provided and shall not be disclosed to any individual or firm outside Address.
<br></br><br></br>
The user of this website will solely be responsible for maintaining the confidentiality of their Display Name and Password and all activities related to the use of the same.
<br></br><br></br>
Any questions, comments, suggestions, or other information submitted to Address in response to this website shall be deemed non-confidential and Address shall be free to use, reproduce, disclose and distribute such information and any ideas contained therein in any manner without limitation.
<br></br><br></br>
No user under the age of 18 and considered as a minor under Indian law shall register as a user and conduct any kind of transactions.
<br></br><br></br>
Address does not charge any fee from the user to log in to the website and utilise the services provided.
<br></br><br></br>
The user agrees not to engage in cross trade or cross sale with other users via the website.
<br></br><br></br>
All registered product names and technology trademarks indicated on this site are registered trademarks of by us or its affiliated entities.
<br></br><br></br>
This site or any part of this site shall not be reproduced, duplicated, copied, sold or resold for any commercial purpose.
<br></br><br></br>
Under no circumstances shall Address, It’s affiliates or any of the contributors to this website and their affiliates, agents and licensors, or their affiliates, agents or licensors be liable to you or anyone else for any damages arising out of use of the information contained on or the products sold through this website, including, without limitation, liability for consequential, special, incidental, indirect, or similar damages, including, without limitation, loss of profit, business, savings, data and third parties’ claims even if we are advised beforehand of the possibility of such damages. 
           
           
      
<br></br><br></br>
Credit<br></br><br></br>
We may offers NET 30 Days terms on orders credit with referals and on previous sale turnover basis. Review of credit application for new customers may take up to several days. For any rush orders, we recommend paying without delay. Please contact credit@kailashj.com for a credit application.
<br></br><br></br>
Prices<br></br><br></br>
For discounted pricing, contact your sales or customer service representative. Pricing is calculated by quantity ordered per SKU. Prices are subject to change without notice. For out of KAILASHJ scale orders, please call for availability and pricing.
<br></br><br></br>
Styles, Pattern, Colors, Designs, and Inventory<br></br><br></br>
For the most current listing of styles, colors, ,patterns, designs, and inventory, visit our website at www.kailashj.com. Please note that based on demand and sales, KAILASHJ reserves the right to discontinue styles, colors and size without prior notification.
<br></br><br></br>
Wholesale Account Qualification<br></br><br></br>
KAILASHJ is a wholesale based company manufacturing products suitable for apparel decorators such as screen printers, embroideries, and promotional products as well and reselling / distributing within MRP. KAILASHJ also sell garments to the general public as well at or within MRP . Our product is intended for companies that embellish for resale and as a resource for their customers. You may not resell any KAILASHJ products to any person or entity, unless you first modify those products by printing, embellishing, or decorating them so as to clearly distinguish them from KAILASHJ’s product line. A reseller’s permit may be required at time of account set up for certain states. Visit our website www.kailashj.com to request and open a wholesale account.
<br></br><br></br>
Garment Specifications<br></br><br></br>
Please note KAILASHJ makes periodic updates to garment specifications. Current inventory may include a balance of garments from a previous specification. KAILASHJ ships by FIFO accounting practice (First in First out). Please contact KAILASHJ Customer Service with any questions.
<br></br><br></br>
Order Placing and Shipping<br></br><br></br>
Most in-stock credit approved orders are shipped within 24 hours of them being placed. Please consult your customer service representative for current processing time. Additions to orders already placed will be considered a “new order” and written as a new purchase order. All merchandise will be shipped EXW, and customer is responsible for freight.
<br></br><br></br>
Confirmations<br></br><br></br>
If you did not receive a confirmation, we did not receive your order. KAILASHJ will not pay for expedited shipments on orders that were not confirmed. Please contact your customer service representative if you did not receive a confirmation.
<br></br><br></br>
Shipping Issues<br></br><br></br>
KAILASHJ will not be responsible for non-delivery, delay, loss, or damages during shipment. All merchandise will be shipped EXW. Customer is responsible for freight.
<br></br><br></br>
Residential Delivery<br></br><br></br>
Shipping to a residential address will incur additional fees for both small packages and pallet shipments which the buyer is responsible for. Buyers should also notify on their purchase orders that the ship-to address is residential.
<br></br><br></br>
Signature Required<br></br><br></br>
KAILASHJ will require signatures on all shipments sent.
<br></br><br></br>
Pick-up and Delivery<br></br><br></br>
KAILASHJ will ship merchandise specified by the customer. All freight claims for missing or damaged merchandise must be filed directly with the carrier. Customer pick-up service is available from 8:30am to 5:30 pm PST, Tuesday through Sunday. Please call ahead to confirm your order is ready to be picked up or for a pick-up time.
<br></br><br></br>
Shipping Discrepancies<br></br><br></br>
Due to possible human error, it is the buyer/consignee's responsibility to verify that the merchandise received matches what was ordered and to resolve any claims with Next Level before garments are altered. Next Level will not accept any claims due to incorrect shipments if garments are altered.
<br></br><br></br>
Embellished Merchandise<br></br><br></br>
It is the buyer’s responsibility to thoroughly inspect and test all merchandise before washing, printing, or any type of decorating. The embellishment processes conducted by the buyer are beyond our control. Thus, once merchandise is altered in any way or form, the buyer cannot return or make any exchanges.
<br></br><br></br>
Returns and Claim Policy<br></br><br></br>
It is the Buyer’s responsibility to inspect and test all merchandise before washing, printing, or any type of decorating. Buyers be aware. Once merchandise is altered in any way, the buyer cannot return or make any exchanges.
<br></br><br></br>
Claims and Cancellations<br></br><br></br>
All claims must be made within 14 days from receipt of goods. Returns will be accepted only with prior authorization. Return shipments will be refused if not accompanied with a Return Merchandise Authorization Code. A 15% restocking fee applies to returns beyond KAILASHJ’s control.
<br></br><br></br>
Discharge Printing<br></br><br></br>
If you plan to discharge print on KAILASHJ garments please note the following: KAILASHJ does its very best to provide garments for discharge printing including reactive dyeing, testing for discharge printability, maintaining tested inventory and shipping each discharge print order from the same fabric/garment lot or advising of any inventory variances. KAILASHJ will ship specific discharge print-tested products when requested for your discharge print business. It is the responsibility of the buyer to advise that the order is for discharge printing. You must test your discharge print process on a number of garments received. If you have any questions regarding the test print results, you can return any unprinted garments for credit or replacement. When in production, if shirts fail to discharge it is up to the printer to stop production and return unprinted shirts. Next Level will not give credit or reimburse for any printed shirts that failed discharge printing. KAILASHJ will not be responsible for shipping charges, printing charges, printed garments or any other charges involved with your discharge print process. If you did not request your order for discharge printing, there is a 15% restocking fee on the returned unprinted garments.
<br></br><br></br>
Country of Origin<br></br><br></br>
KAILASHJ  manufactures garments in INDIA. 
in future, KAILASHJ may or may not manufacture garments in various locations. If a particular origin is required, it must be indicated on the purchase order(s) as shipments may contain multiple origin. If a particular origin was not listed on the purchase order(s), all shipping and restocking fees are the responsibilities of the buyer.
<br></br><br></br>
Re-Label orders are available from our Bulk Order Customers On request subject to availabilty. Please confirm quotes and minimums.
<br></br><br></br>
Samples will be shipped at a per-piece unit price @ premium price, plus freight cost. Samples are available upon request and cannot be returned.
<br></br><br></br>
Burnout Product<br></br><br></br>
Due to the nature of the burnout process, no two garments are alike. Variation in the color and/or pattern will be apparent and contributes to the unique properties of the product.


</p>
     
                </Dialog>


<Dialog header="policy" visible={displayBasic2} style={{ width: '90vw' , height: '90vw' }} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                   
{/* <h4>Privacy</h4> */}
 
<h4>Shipping</h4>
<p>
KAILASHJ's preferred shipping methods are dELHIvERY. However, we provide customers the option to ship via Collect and Third Party shipping accounts with corresponding assigned rates. All shipments are EXW. Customers are responsible for freight costs.
 
<br></br><br></br>
Order Placing and Shipping - Most in-stock credit approved orders are shipped the same day they are placed. Please consult your customer service representative for shipment date confirmation. Additions to orders already placed will be considered a “new order” and written as a new purchase order. All merchandise will be shipped FOB origin.
 
<br></br><br></br>
Confirmations - If you did not receive a confirmation, we did not receive your order. KAILASHJ will not pay for expedited shipments on orders that were not confirmed. Please contact your customer service representative if you did not receive a confirmation.
 
<br></br><br></br>
Shipping Issues - KAILASHJ will not be responsible for non-delivery, delay, loss, or damages during shipment. All merchandise is shipped FOB origin. Insuring the product is up to the buyer, and is not the responsibility of KAILASHJ.
 
<br></br><br></br>
Pick-up and Delivery - KAILASHJ will ship merchandise specified by the customer. All freight claims for missing or damaged merchandise must be filed directly with the carrier. Customer pick-up service is available from 12:00 am - 5:00 pm IST at our Delhi warehouse Tuesday through Sunday. Email confirmation with BOL documentation will be sent once your order is ready. You may also call to confirm your order is ready for pick up.
 
<br></br><br></br>
Shipping Discrepancies - Due to possible human error, it is the buyer/consignee's responsibility to verify that the merchandise received matches what was ordered and resolve any claims with Next Level before garments are altered. Next Level will not accept any claims if garments are altered due to incorrect shipments.
</p>
{/* <h4>Refund</h4> */}
                   
<h4>Return</h4>
<p>
New, unworn and unused items that do not meet your expectations in fit, quality, or style can be returned within 14 days for a full refund of the purchase price (shipping charges will not be refunded).    Defective items in any condition can be returned for a full refund of the purchase price and shipping costs within 14 days. If you feel that you received a damaged or defective item please contact us.  
 
<br></br><br></br>
To return an item, simply ship it back to us in its original packaging with the tags intact. Include a copy of your receipt, proof of payment, or (if all else fails) your name, phone number and credit card number . 


<br></br><br></br>
WE DO NOT PROVIDE RETURN SHIPPING LABELS FOR RETURNS. 

 
<br></br><br></br>

WE DO NOT ACCEPT RETURNS FOR LIMITED EDITION ITEMS THAT HAVE LIMITED QUANTITY AND ARE PRESOLD.


<br></br><br></br>
Your patronage is important to us and appreciated. If you have any questions or concerns please contact us at q@kailashj.com with the subject "Return Policy". Thanks for stopping by. 

<br></br><br></br>
Return Address: 

<br></br><br></br>
current available warehouse address to be communicated via mail or directly collection / drop with Shipment handler/transport on approved.  

<br></br><br></br>
We will confirm receipt of your return and credit your credit card with the purchase price. Items received as gifts can be returned for store credit. 

<br></br><br></br>
Exchanges 
We are happy to exchange unused items for alternate colors and sizes within 14 days. Simply return the item to our store with the receipt. Please make sure to include which item, size, color you would like in exchange. You can also call or email us directly to help in selecting an alternative item or reorder online.
         
</p>
 
          
                </Dialog>

               
                <Dialog header="MOBILE NUMBER" visible={loginpop} style={{ width: '90vw' , height: '90vw' }} footer={renderFooter_OTP('loginpop')} onHide={() => onHide('loginpop')}>
                   
                   
                   </Dialog>
             



  </>

 )


}
 
 